import { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";

import { VOUCH_CODE } from "../../constants/session";

const VouchRedirect = () => {
  const { code } = useParams();
  const setVouchCode = useLocalStorage(VOUCH_CODE)[1];

  useEffect(() => {
    setVouchCode(code);
  }, []);

  return <Redirect to="/" />;
};

export default VouchRedirect;
