import axios from "axios";
import { gql } from "@apollo/client";
import { mergeAndDedupeRefsById } from "../utils/object";

import { TOKEN } from "../constants/session";

const ENDPOINT_URL = process.env.REACT_APP_ENDPOINT_URL;

export const insightsTypePolicy = {
  keyArgs: false,
  merge: mergeAndDedupeRefsById,
  read: (insights, { args, readField }) => {
    const byBrief = args?.input?.filter?.briefId;
    if (insights === undefined) {
      return insights;
    }
    if (byBrief !== undefined) {
      return insights.filter((insight) => readField("briefId", insight) === byBrief);
    }
    return insights;
  },
};

const getDraft = async (payload) => {
  const token = localStorage.getItem(TOKEN);

  const res = await axios.post(
    `${ENDPOINT_URL}/api/get-draft`,
    {
      token,
      payload,
    },
    {
      responseType: "json",
    },
  );

  console.log("insight_service getDraft:", res.data);
  return res.data;
};

const saveDraft = async (payload) => {
  const token = localStorage.getItem(TOKEN);

  const res = await axios.post(
    `${ENDPOINT_URL}/api/save-draft`,
    {
      token,
      payload,
    },
    {
      responseType: "json",
    },
  );

  console.log("save draft got res", res.data);
  return res.data;
};

const exportDailyInsights = async (briefId) => {
  const token = localStorage.getItem(TOKEN);

  const res = await axios.post(
    `${ENDPOINT_URL}/api/export-daily-insights`,
    {
      token,
      briefId,
    },
    {
      responseType: "json",
    },
  );

  return res.data;
};

const listByConsultant = gql`
  query ($limit: Int!, $consultantId: String!) {
    insights(input: { consultantId: $consultantId, pagination: { limit: $limit, offset: 0 } }) {
      id
      brief {
        brand {
          name
          logoImage
        }
      }
      answers {
        id
        text
        filename
        question {
          index
          title
        }
      }
    }
  }
`;

const add = gql`
  mutation ($answers: [AddInsightAnswerInput!]!, $briefId: String!, $consultantId: String!) {
    addInsight(input: { briefId: $briefId, consultantId: $consultantId, answers: $answers }) {
      id
    }
  }
`;

const listByBriefIdForClient = gql`
  query ($briefId: String!, $clientId: String!, $limit: Int!, $offset: Int!) {
    insights(
      input: {
        clientId: $clientId
        filter: { briefId: $briefId }
        pagination: { limit: $limit, offset: $offset }
      }
    ) {
      id
      createdAt
      status
      unread
      briefId
      answers {
        id
        filename
        text
        ai
        plagiarism
        question {
          id
          title
          index
          type
          min
          max
          multiple
        }
      }
      consultant {
        id
        warningsCount
        address {
          address
        }
        gender {
          title
        }
        dateOfBirth
      }
    }
  }
`;

const listByBriefIdForAdmin = gql`
  query ($briefId: String!, $clientId: String!, $limit: Int!, $offset: Int!) {
    insights(
      input: {
        clientId: $clientId
        filter: { briefId: $briefId }
        pagination: { limit: $limit, offset: $offset }
      }
    ) {
      id
      createdAt
      status
      unread
      briefId
      answers {
        id
        filename
        text
        ai
        plagiarism
        question {
          id
          title
          index
          type
          min
          max
          multiple
        }
      }
      consultant {
        id
        warningsCount
        address {
          address
        }
        user {
          firstName
          lastName
          email
        }
        gender {
          title
        }
        dateOfBirth
      }
    }
  }
`;

export default {
  listByConsultant,
  add,
  getDraft,
  saveDraft,
  listByBriefIdForClient,
  listByBriefIdForAdmin,
  exportDailyInsights,
};
