import useAuthentication from "../../../hooks/useAuthentication";
import PageNav from "./PageNav/PageNav";

import styles from "./Footer.module.scss";

const Footer = () => {
  const isAuthenticated = useAuthentication();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div
      className={`d-block d-md-none bg-white border-top ${styles.footer}`}
      style={{ position: "fixed", bottom: 0, zIndex: 1 }}
    >
      <PageNav className="container" />
    </div>
  );
};

export default Footer;
