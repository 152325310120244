import { gql } from "@apollo/client";
import { mergeAndDedupeRefsById } from "../utils/object";

export const brandsTypePolicy = {
  keyArgs: false,
  merge: mergeAndDedupeRefsById,
};

const get = gql`
  query ($brandId: String!) {
    brand(input: { brandId: $brandId }) {
      bio
      category
      coverImage
      createdAt
      id
      links {
        url
      }
      logoImage
      name
      website
      engagement {
        consultants
        count
      }
    }
  }
`;

const update = gql`
  mutation (
    $id: String!
    $bio: String
    $coverImage: String
    $logoImage: String
    $name: String!
    $links: [String!]
    $website: String
    $category: String
    $active: Boolean
    $password: [ClientPassword!]
  ) {
    editBrand(
      input: {
        brandId: $id
        bio: $bio
        category: $category
        coverImage: $coverImage
        logoImage: $logoImage
        name: $name
        usefulLinks: $links
        website: $website
        active: $active
        password: $password
      }
    ) {
      bio
      coverImage
      id
      links {
        url
      }
      logoImage
      name
      website
      active
    }
  }
`;

const list = gql`
  query ($limit: Int!, $offset: Int!) {
    brands(input: { pagination: { limit: $limit, offset: $offset } }) {
      id
      name
      category
      logoImage
      coverImage
      website
      bio
      active
      links {
        url
      }
      engagement {
        consultants
        count
      }
      createdAt
    }
  }
`;

const statistics = gql`
  query ($brandId: String!, $since: String) {
    brandStatistics(input: { brandId: $brandId, since: $since }) {
      liveBriefs
      briefViews
      insightsReceived
    }
  }
`;

const create = gql`
  mutation (
    $active: Boolean!
    $bio: String
    $category: String
    $client: AddBrandClientInput!
    $coverImage: String
    $logoImage: String
    $name: String!
    $links: [String!]
    $website: String
  ) {
    addBrand(
      input: {
        active: $active
        bio: $bio
        category: $category
        client: $client
        coverImage: $coverImage
        logoImage: $logoImage
        name: $name
        usefulLinks: $links
        website: $website
      }
    ) {
      bio
      coverImage
      id
      links {
        url
      }
      logoImage
      name
      website
      active
    }
  }
`;

export default {
  get,
  update,
  list,
  statistics,
  create,
};
