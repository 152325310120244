import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import logOut from "../../utils/logOut";
import { ReactComponent as LogOutIcon } from "../../assets/log-out.svg";
import ProfileImage from "./ProfileImage";

const UserInfo = ({ getDataFromResult, query, variables }) => {
  const { loading, error, data } = useQuery(query, { variables });

  if (loading || error) {
    return null;
  }

  const { firstName, lastName, profileImage } = getDataFromResult(data);

  return (
    <div className="d-flex align-items-center text-white">
      <Button
        id="btn-logout"
        variant="link"
        className="d-none d-md-block text-white mx-2 font-size-14"
        onClick={logOut}
      >
        <FormattedMessage id="nav.logOut" />
      </Button>
      <ProfileImage firstName={firstName} lastName={lastName} src={profileImage} />
      <button className="btn-reset d-md-none ml-2" type="button" onClick={logOut}>
        <LogOutIcon className="icon-white" />
      </button>
    </div>
  );
};

UserInfo.propTypes = {
  getDataFromResult: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  query: PropTypes.object.isRequired,
  variables: PropTypes.shape({}),
};

UserInfo.defaultProps = {
  variables: {},
};

export default UserInfo;
