import useLocalStorage from "react-use-localstorage";
import { USER_ID, USER_TYPE } from "../constants/session";

const useSession = () => {
  const [userId] = useLocalStorage(USER_ID);
  const [userType] = useLocalStorage(USER_TYPE);
  return { userId, userType };
};

export default useSession;
