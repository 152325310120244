import useSession from "../../../../hooks/useSession";
import { UserType } from "../../../../constants/userType";
import UserService from "../../../../service/user";
import ClientService from "../../../../service/client";
import UserInfo from "../../../common/UserInfo";

const UserInfoContainer = () => {
  const { userId, userType } = useSession();
  const isAdmin = userType === UserType.ADMIN;

  return isAdmin ? (
    <UserInfo
      query={UserService.get}
      variables={{ userId }}
      getDataFromResult={({ user }) => user}
    />
  ) : (
    <UserInfo
      query={ClientService.get}
      variables={{ clientId: userId }}
      getDataFromResult={({ client: { user } }) => user}
    />
  );
};

export default UserInfoContainer;
