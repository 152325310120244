import { Fragment, lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Settings as DateTimeSettings } from "luxon";

import TopNav, { DARK, NORMAL } from "./Nav/TopNav/TopNav";
import LeftNav from "./Nav/LeftNav/LeftNav";
import Loader from "../Loader";
import useAuthentication from "../../hooks/useAuthentication";
import { AuthPaths, ClientPaths as Paths } from "../../constants/paths";
import { UserType } from "../../constants/userType";
import useSession from "../../hooks/useSession";
import UserInfoContainer from "./Nav/TopNav/UserInfoContainer";

const ImagenClient = () => {
  const isAuthenticated = useAuthentication();
  const { userType } = useSession();
  const isAdmin = userType === UserType.ADMIN;

  useEffect(() => {
    DateTimeSettings.defaultLocale = "en";
  }, []);

  const Admin = lazy(() => import("./Admin/Admin"));
  const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
  const UserList = lazy(() => import("./Admin/UserListPage"));
  const AdminClientQuestions = lazy(() => import("./Admin/AdminClientQuestions"));
  const AdminUserPoints = lazy(() => import("./Admin/AdminUserPoints"));
  const AdminUserConsultantPoints = lazy(() => import("./Admin/AdminUserConsultantPoints"));
  const AdminUserLeaderboard = lazy(() => import("./Admin/AdminUserLeaderboard"));
  const BriefContainer = lazy(() => import("./Brief/BriefContainer"));
  const InsightsUserTypeWrapper = lazy(() =>
    import("./Insights/InsightWrapper/InsightsUserTypeWrapper"),
  );
  const Settings = lazy(() => import("./Settings/Settings"));

  return (
    <Fragment>
      <TopNav
        variant={isAdmin ? DARK : NORMAL}
        isAdmin={isAdmin}
        right={isAuthenticated && <UserInfoContainer />}
      />
      <div className="flex-grow-1 d-flex align-items-stretch">
        {isAuthenticated && <LeftNav />}
        <div className="flex-grow-1 pb-5">
          <Suspense fallback={<Loader />}>
            <Switch>
              {!isAuthenticated && (
                <Route>
                  <Redirect to={AuthPaths.loginUserType(UserType.CLIENT)} />
                </Route>
              )}
              <Route path={Paths.brief()}>
                <BriefContainer />
              </Route>
              <Route path={Paths.dashboard()}>
                <Dashboard />
              </Route>
              <Route path={Paths.users()}>
                <UserList />
              </Route>
              <Route path={Paths.userConsultantPoints(":id")}>
                <AdminUserConsultantPoints />
              </Route>
              <Route path={Paths.userLeaderboard()}>
                <AdminUserLeaderboard />
              </Route>
              <Route path={Paths.clientQuestions()}>
                <AdminClientQuestions />
              </Route>
              <Route path={Paths.userPoints()}>
                <AdminUserPoints />
              </Route>
              <Route path={Paths.insights(":userType")}>
                <InsightsUserTypeWrapper />
              </Route>
              <Route path={Paths.settingPanel(":panelId")}>
                <Settings />
              </Route>
              <Route path={Paths.settings()}>
                <Redirect to={Paths.settingPanel("brand")} />
              </Route>
              <Route path={Paths.admin()}>
                <Admin />
              </Route>
              <Route path="/">
                <Redirect to={Paths.dashboard()} />
              </Route>
            </Switch>
          </Suspense>
        </div>
      </div>
    </Fragment>
  );
};

export default ImagenClient;
