export const filterKeys = (object, keys) =>
  Object.entries(object).reduce(
    (filtered, [key, value]) =>
      keys.includes(key) && value !== "" && value !== null && value?.length !== 0
        ? {
            ...filtered,
            [key]: Array.isArray(value) ? value.filter((val) => val.password !== "") : value,
          }
        : filtered,
    {},
  );

export const mergeAndDedupeRefsById = (existing = [], incoming, { readField, args }) => {
  if (args?.input?.filter?.search) {
    return incoming;
  }

  const result = [...existing, ...incoming].filter(
    (item, i, self) =>
      i === self.findIndex((ref) => readField("id", ref) === readField("id", item)),
  );

  return result;
};
