import { Switch, Route, Redirect } from "react-router-dom";
import { lazy, Suspense } from "react";
import { AuthPaths, ConsultantPaths as Paths } from "../../constants/paths";
import styles from "./ImagenConsultant.module.scss";
import Loader from "../Loader";
import Landing from "./Landing/Landing";
import Confirmation from "./Confirmation/Confirmation";
import { UserType } from "../../constants/userType";
import useAuthentication from "../../hooks/useAuthentication";

const ImagenConsultant = () => {
  const isAuthenticated = useAuthentication();

  const CreateAccount = lazy(() => import("./CreateAccount/CreateAccount"));
  const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
  const Brand = lazy(() => import("./Brand/Brand"));
  const Brief = lazy(() => import("./Brief/Brief"));
  const AnswerBrief = lazy(() => import("./Brief/AnswerBrief/AnswerBrief"));
  const Verify = lazy(() => import("./Verify/Verify"));
  const Complete = lazy(() => import("./Complete/Complete"));
  const Leaderboard = lazy(() => import("./Leaderboard/Leaderboard"));
  const UploadPoints = lazy(() => import("./UploadPoints/UploadPoints"));
  const CompleteProfile = lazy(() => import("./CompleteProfile/CompleteProfile"));
  const EditProfile = lazy(() => import("./EditProfile/EditProfile"));

  return (
    <div className={`flex-grow-1 ${styles.wrapper} d-flex flex-column pb-md-5`}>
      <div className={`${styles.background}`} />
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path={Paths.createAccount()}>
            <CreateAccount />
          </Route>
          <Route path={Paths.accountCreated()}>
            <Confirmation />
          </Route>
          {!isAuthenticated && (
            <Route>
              <Redirect to={AuthPaths.loginUserType(UserType.CONSULTANT)} />
            </Route>
          )}
          <Route path={Paths.dashboard()}>
            <Dashboard />
          </Route>
          <Route path={Paths.brand(":brandId")}>
            <Brand />
          </Route>
          <Route path={Paths.answerBrief(":briefId")}>
            <AnswerBrief />
          </Route>
          <Route path={Paths.viewBrief(":briefId")}>
            <Brief />
          </Route>
          <Route path={Paths.verify()}>
            <Verify />
          </Route>
          <Route path={Paths.leaderboard()}>
            <Leaderboard />
          </Route>
          <Route path={Paths.uploadPoints()}>
            <UploadPoints />
          </Route>
          <Route path={Paths.complete()}>
            <Complete />
          </Route>
          <Route path={Paths.completeProfile()}>
            <CompleteProfile />
          </Route>
          <Route path={Paths.editProfile()}>
            <EditProfile />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
};

export default ImagenConsultant;
