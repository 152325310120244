import { useMutation } from "@apollo/client";
import useLocalStorage from "react-use-localstorage";

import { getUserTypeConfig, UserType } from "../constants/userType";
import { TOKEN, USER_ID, USER_TYPE } from "../constants/session";
import { AuthPaths as Paths } from "../constants/paths";
import { trackLoginUser, identifyUser } from "../service/analytics";

const useLogin = (userType) => {
  const { mutation, dataKey, idKey } = getUserTypeConfig(userType);
  const [logIn, { loading }] = useMutation(mutation);
  const setToken = useLocalStorage(TOKEN)[1];
  const setUserId = useLocalStorage(USER_ID)[1];
  const setUserType = useLocalStorage(USER_TYPE)[1];

  return {
    loading,
    logIn: async (email, password) => {
      trackLoginUser(userType);

      const { data } = await logIn({
        variables: { email, password },
      });

      const {
        [dataKey]: {
          jwt,
          [idKey]: { id, user, firstName, lastName },
        },
      } = data;

      const traits = {
        userType,
        firstName: user?.firstName || firstName,
        lastName: user?.lastName || lastName,
        email: user?.email || email,
      };

      if (userType === UserType.CONSULTANT) {
        const {
          [dataKey]: {
            [idKey]: { address, dateOfBirth, gender },
          },
        } = data;
        traits.country = address.country;
        traits.dateOfBirth = dateOfBirth;
        traits.gender = gender?.title;
      }

      identifyUser(id, traits);

      setToken(jwt);
      setUserId(id);
      setUserType(userType);
      window.location.href = Paths.loginUserType(userType);
    },
  };
};

export default useLogin;
