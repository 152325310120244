import { gql } from "@apollo/client";

const get = gql`
  query ($clientId: String!) {
    client(input: { clientId: $clientId }) {
      id
      user {
        email
        firstName
        lastName
        profileImage
      }
      brand {
        id
        name
        logoImage
      }
    }
  }
`;

const listByBrandId = gql`
  query ($brandId: String!) {
    clients(input: { brandId: $brandId }) {
      id
      user {
        email
        firstName
        lastName
        profileImage
      }
    }
  }
`;

const update = gql`
  mutation (
    $id: String!
    $email: String
    $firstName: String
    $lastName: String
    $profileImage: String
  ) {
    editClient(
      input: {
        clientId: $id
        email: $email
        firstName: $firstName
        lastName: $lastName
        profileImage: $profileImage
      }
    ) {
      id
      user {
        email
        firstName
        lastName
        profileImage
      }
      brand {
        id
        name
        logoImage
      }
    }
  }
`;

const logIn = gql`
  mutation ($email: String!, $password: String!) {
    loginClient(input: { email: $email, password: $password }) {
      jwt
      client {
        id
        user {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export default {
  get,
  update,
  listByBrandId,
  logIn,
};
