import de from "./de.json";
import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import it from "./it.json";
import pt from "./pt.json";

export const DEFAULT_LOCALE = "en";

export const localesMap = {
  en: "English 🇬🇧",
  de: "German 🇩🇪",
  es: "Spanish 🇪🇸",
  fr: "French 🇫🇷",
  it: "Italian 🇮🇹",
  pt: "Portuguese 🇵🇹",
};

const locales = {
  en,
  de: { ...en, ...de },
  es: { ...en, ...es },
  fr: { ...en, ...fr },
  it: { ...en, ...it },
  pt: { ...en, ...pt },
};

export default locales;
