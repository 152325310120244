import { Route, Switch } from "react-router-dom";

import Intl from "./Intl";
import ImagenClient from "./client/ImagenClient";
import ImagenConsultant from "./consultant/ImagenConsultant";
import VouchRedirect from "./consultant/VouchRedirect";
import ImagenAuth from "./auth/ImagenAuth";

import {
  ADMIN_APP_PREFIX,
  CLIENT_APP_PREFIX,
  LOGIN_PREFIX,
  RESET_PASSWORD_PREFIX,
  VOUCH_REDIRECT_PREFIX,
} from "../constants/paths";

const Router = () => (
  <Intl>
    <div className="min-vh-100 d-flex flex-column">
      <Switch>
        <Route path={`/${VOUCH_REDIRECT_PREFIX}`}>
          <VouchRedirect />
        </Route>
        <Route path={`/${CLIENT_APP_PREFIX}`}>
          <ImagenClient />
        </Route>
        <Route path={`/${ADMIN_APP_PREFIX}`}>
          <ImagenClient />
        </Route>
        <Route path={`/${RESET_PASSWORD_PREFIX}`}>
          <ImagenAuth />
        </Route>
        <Route path={`/${LOGIN_PREFIX}`}>
          <ImagenAuth />
        </Route>
        <Route path="/">
          <ImagenConsultant />
        </Route>
      </Switch>
    </div>
  </Intl>
);

export default Router;
