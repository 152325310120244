import { useState } from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import LoginForm from "./LoginForm/LoginForm";
import useLogin from "../../hooks/useLogin";
import { UserType } from "../../constants/userType";
import Button, { LIGHT } from "../common/Button/Button";
import { ConsultantPaths } from "../../constants/paths";
import { trackCreateAccountButton } from "../../service/analytics";

const Login = () => {
  const { userType } = useParams();
  const { formatMessage } = useIntl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { logIn, loading } = useLogin(userType);

  const onChange =
    (set) =>
    ({ target: { value } }) =>
      set(value);

  const onSubmit = async (event) => {
    event.preventDefault();
    setError(false);
    try {
      await logIn(email, password);
    } catch (e) {
      setError(true);
      Sentry.captureException(e);
    }
  };

  return (
    <LoginForm
      titleMessage={error ? "auth.login.errorTitle" : "auth.login.title"}
      bodyMessage={error ? "auth.login.errorSubtitle" : "auth.login.subtitle"}
      submitMessage="auth.login.submit"
      className={error && "text-danger"}
      onClick={onSubmit}
      isLoading={loading}
      isDisabled={loading || email === "" || password === ""}
      below={
        userType === UserType.CONSULTANT && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            className="text-decoration-none w-100 mt-4 mb-3"
            to={ConsultantPaths.createAccount()}
          >
            <Button
              variant={LIGHT}
              className="my-2 font-size-18 w-100 d-flex align-items-center justify-content-center"
              disabled={loading}
              type="button"
              onClick={() => trackCreateAccountButton("login")}
            >
              <FormattedMessage id="auth.login.create" />
            </Button>
          </Link>
        )
      }
    >
      <Form.Control
        className="w-100 my-2"
        type="email"
        placeholder={formatMessage({ id: "auth.login.email" })}
        value={email}
        onChange={onChange(setEmail)}
      />
      <Form.Control
        className="w-100 my-2"
        type="password"
        placeholder={formatMessage({ id: "auth.login.password" })}
        value={password}
        onChange={onChange(setPassword)}
      />
    </LoginForm>
  );
};

export default Login;
