import posthog from "posthog-js";

const isProduction = process.env.NODE_ENV === "production";

const track = (name, props) => {
  window.analytics?.track(name, props);
  isProduction && posthog.capture(name, props);
};

export const identifyUser = (id, traits) => {
  window.analytics?.identify(id, traits);
  isProduction && posthog.identify(id, traits);
};

export const trackCreateAccountButton = (source) => {
  track("Create Account Clicked", { source });
};

export const trackRegistrationProgress = (props) => {
  track("Creating Consultant", props);
};

export const trackRegisterConsultant = () => {
  track("Consultant Account Created");
};

export const trackRegisterComplete = () => {
  track("Consultant Account Completed");
};

export const trackForgotPassword = (type) => {
  track("Reset Password Clicked", { type });
};

export const trackLoginUser = (type) => {
  track("User Logged In", { type });
};

export const trackLogoutUser = () => {
  track("User Logged Out");
};

export const trackViewBrand = (props) => {
  track("View Brand Clicked", props);
};

export const trackOpenBrief = (props) => {
  track("Open Brief Clicked", props);
};

export const trackAcceptBrief = (props) => {
  track("Brief Accepted", props);
};

export const trackDeclineBrief = (props) => {
  track("Brief Declined", props);
};

export const trackSubmitInsight = (props) => {
  track("Insight Submitted", props);
};

export const trackViewBrief = (props) => {
  track("View Brief Clicked", props);
};

export const trackCreateNewBrief = () => {
  track("Create New Brief Clicked");
};

export const trackBriefCreated = (props) => {
  track("Brief Created", props);
};

export const trackUpdateBrief = (props) => {
  track("Brief Updated", props);
};

export const trackLiveBrief = (props) => {
  track("Brief Live", props);
};

export const trackCancelBrief = (props) => {
  track("Brief Cancelled", props);
};

export const trackDeleteBrief = (props) => {
  track("Brief Deleted", props);
};

export const trackDuplicateBrief = (props) => {
  track("Brief Duplicated", props);
};

export const trackExtendBrief = (props) => {
  track("Brief Extended", props);
};

export const trackViewInsights = (props) => {
  track("Insights Viewed", props);
};

export const trackExportInsights = (props) => {
  track("Insights Exported", props);
};

export const trackUpdateBrandProfile = (props) => {
  track("Brand Profile Updated", props);
};

export const trackUpdateTeamMember = (props) => {
  track("Team Member Updated", props);
};
