import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

const Loader = ({ className, size }) => (
  <div className={`h-100 d-flex align-items-center justify-content-center ${className}`}>
    <Spinner size={size} className="text-primary" animation="border" />
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

Loader.defaultProps = {
  className: "",
  size: "",
};

export default Loader;
