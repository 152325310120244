import { gql } from "@apollo/client";

const logIn = gql`
  mutation ($email: String!, $password: String!) {
    loginAdmin(input: { email: $email, password: $password }) {
      jwt
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const statistics = gql`
  query ($since: String) {
    adminStatistics(input: { since: $since }) {
      totalBrands
      totalConsultants
      totalLiveBriefs
    }
  }
`;

const listConsultants = gql`
  query (
    $clientId: String!
    $ethnicity: String
    $interests: String
    $gender: String
    $minAge: Int
    $maxAge: Int
    $country: String
    $location: String
    $createdAtStart: String
    $createdAtEnd: String
  ) {
    listConsultants(
      input: {
        clientId: $clientId
        ethnicity: $ethnicity
        interests: $interests
        country: $country
        location: $location
        gender: $gender
        minAge: $minAge
        maxAge: $maxAge
        createdAtStart: $createdAtStart
        createdAtEnd: $createdAtEnd
      }
    ) {
      id
      user {
        email
        firstName
        lastName
        createdAt
        contactNumber
      }
    }
  }
`;

export default {
  logIn,
  statistics,
  listConsultants,
};
