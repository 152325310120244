import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useLocalStorage from "react-use-localstorage";
import { IntlProvider } from "react-intl";
import { Settings as DateTimeSettings } from "luxon";

import LocaleContext from "../utils/LocaleContext";
import locales, { DEFAULT_LOCALE } from "../locale";
import { LOCALE as LOCALE_SESSION_KEY } from "../constants/session";

import Loader from "./Loader";

const Intl = ({ children }) => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const [storedLocale, persistLocale] = useLocalStorage(LOCALE_SESSION_KEY, DEFAULT_LOCALE);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    if (storedLocale !== DEFAULT_LOCALE && Object.keys(locales).includes(storedLocale)) {
      setLocale(storedLocale);
    }
  }, []);

  useEffect(() => {
    setMessages(locales[locale]);
    persistLocale(locale);
    DateTimeSettings.defaultLocale = locale;
  }, [locale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <LocaleContext.Provider value={{ setLocale, locale }}>
        {Object.keys(messages).length === 0 ? (
          <div>
            <Loader />
          </div>
        ) : (
          children
        )}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

Intl.propTypes = {
  children: PropTypes.node,
};

Intl.defaultProps = {
  children: null,
};

export default Intl;
