import { from, ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { AUTH_ERRORS } from "./constants/error";
import logOut from "./utils/logOut";
import { briefsTypePolicy } from "./service/brief";
import { consultantsTypePolicy } from "./service/consultant";
import { brandsTypePolicy } from "./service/brand";
import { insightsTypePolicy } from "./service/insight";

const errorHandler = onError(({ graphQLErrors }) => {
  if (
    (graphQLErrors || []).some(({ message }) =>
      AUTH_ERRORS.some((authError) => message.includes(authError)),
    )
  ) {
    logOut();
  }
});

const createClient = (token) => {
  const link = from([
    errorHandler,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      headers: {
        authorization: token,
      },
    }),
  ]);

  return new ApolloClient({
    name: "react-web",
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            briefs: briefsTypePolicy,
            consultants: consultantsTypePolicy,
            brands: brandsTypePolicy,
            insights: insightsTypePolicy,
          },
        },
      },
    }),
  });
};

export default createClient;
