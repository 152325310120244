import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import NavButton from "../../../common/NavButton/NavButton";
import { ConsultantPaths as Paths } from "../../../../constants/paths";
import { ReactComponent as HomeIcon } from "../../../../assets/home.svg";

const items = {
  [Paths.dashboard()]: HomeIcon,
};

const PageNav = ({ className }) => {
  const { pathname } = useLocation();

  const currentPage = useMemo(() => `/${pathname.split("/")[1]}`, [pathname]);

  return (
    <div className={`${className} d-flex justify-content-around`}>
      {Object.keys(items).map((path) => (
        <NavButton key={path} path={path} Icon={items[path]} isCurrent={currentPage === path} />
      ))}
    </div>
  );
};

PageNav.propTypes = {
  className: PropTypes.string,
  showProfile: PropTypes.bool,
};

PageNav.defaultProps = {
  className: "",
  showProfile: false,
};

export default PageNav;
