import { nanoid } from "nanoid";

export const arrayOf = (n) => [...new Array(n).fill().map(() => nanoid(4))];

export const asyncForEach = async (array, callback) => {
  for (let i = 0; i < array.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[i], i, array);
  }
};

export const parseNullableJson = (value) => JSON.parse(value || "[]");

export const splitIntoChunks = (list, maxLength) => {
  let chunkedArray = [];
  if (Array.isArray(list)) {
    while (list.length) {
      chunkedArray.push(list.splice(0, maxLength));
    }
  } else {
    let tempObject = {};
    Object.keys(list).forEach((key, index) => {
      tempObject[key] = list[key];
      if (Object.keys(tempObject).length === maxLength || index === Object.keys(list).length - 1) {
        chunkedArray.push(tempObject);
        tempObject = {};
      }
    });
  }

  return chunkedArray;
};
