import { gql } from "@apollo/client";
import { mergeAndDedupeRefsById } from "../utils/object";

export const consultantsTypePolicy = {
  keyArgs: false,
  merge: mergeAndDedupeRefsById,
};

const register = gql`
  mutation (
    $address: String!
    $college: String
    $collegeCourseId: String
    $dateOfBirth: String!
    $email: String!
    $ethnicityId: String!
    $firstName: String!
    $freeSchoolMeals: Boolean!
    $disability: Boolean!
    $genderId: String!
    $interestsId: [String!]
    $lastName: String!
    $lat: String!
    $lng: String!
    $password: String!
    $referralId: String!
    $school: String
    $sexualOrientationId: String!
    $siblings: [SiblingsInput!]
    $university: String
    $universityCourseId: String
    $vouchCode: String
  ) {
    signupConsultant(
      input: {
        address: $address
        college: $college
        collegeCourseId: $collegeCourseId
        dateOfBirth: $dateOfBirth
        email: $email
        ethnicityId: $ethnicityId
        firstName: $firstName
        freeSchoolMeals: $freeSchoolMeals
        disability: $disability
        genderId: $genderId
        interestsId: $interestsId
        lastName: $lastName
        lat: $lat
        lng: $lng
        password: $password
        referralId: $referralId
        school: $school
        sexualOrientationId: $sexualOrientationId
        siblings: $siblings
        university: $university
        universityCourseId: $universityCourseId
        vouchCode: $vouchCode
      }
    ) {
      consultant {
        id
      }
    }
  }
`;

const complete = gql`
  mutation (
    $id: String!
    $school: String
    $college: String
    $collegeCourseId: String
    $ethnicityId: String
    $freeSchoolMeals: Boolean
    $disability: Boolean!
    $genderId: String
    $interestsId: [String!]
    $referralId: String
    $sexualOrientationId: String
    $siblings: [SiblingsInput!]
    $university: String
    $universityCourseId: String
  ) {
    signupComplete(
      input: {
        consultantId: $id
        college: $college
        collegeCourseId: $collegeCourseId
        ethnicityId: $ethnicityId
        freeSchoolMeals: $freeSchoolMeals
        disability: $disability
        genderId: $genderId
        interestsId: $interestsId
        referralId: $referralId
        school: $school
        sexualOrientationId: $sexualOrientationId
        siblings: $siblings
        university: $university
        universityCourseId: $universityCourseId
      }
    ) {
      id
    }
  }
`;

const get = gql`
  query ($consultantId: String!) {
    consultant(input: { consultantId: $consultantId }) {
      rank
      points
      address {
        address
        lat
        lng
        country
      }
      college
      collegeCourse {
        id
        title
      }
      consultantInterests {
        interest {
          name
          emoji
        }
      }
      createdAt
      dateOfBirth
      ethnicity {
        id
        title
      }
      freeSchoolMeals
      disability
      gender {
        id
        title
      }
      id
      referral {
        title
      }
      school
      sexualOrientation {
        id
        title
      }
      siblings {
        dateOfBirth
      }
      university
      universityCourse {
        id
        title
      }
      isCompleted
      banStartedAt
      feedbackRating
      user {
        block
        email
        contactNumber
        firstName
        lastName
        profileImage
        createdAt
        allowDailyBriefPushNotifications
      }
      warningsCount
    }
  }
`;

const logIn = gql`
  mutation ($email: String!, $password: String!) {
    loginConsultant(input: { email: $email, password: $password }) {
      jwt
      consultant {
        id
        address {
          country
        }
        dateOfBirth
        gender {
          title
        }
        user {
          email
          firstName
          lastName
        }
      }
    }
  }
`;

const list = gql`
  query ($offset: Int!, $limit: Int!, $search: String = "") {
    consultants(
      input: { pagination: { limit: $limit, offset: $offset }, filter: { search: $search } }
    ) {
      points
      address {
        address
        lat
        lng
      }
      college
      collegeCourse {
        id
        title
      }
      consultantInterests {
        id
        interest {
          id
          name
          emoji
        }
      }
      createdAt
      dateOfBirth
      ethnicity {
        id
        title
      }
      freeSchoolMeals
      disability
      gender {
        id
        title
      }
      id
      insightCount
      referral {
        id
        title
      }
      school
      sexualOrientation {
        id
        title
      }
      siblings {
        dateOfBirth
      }
      university
      universityCourse {
        id
        title
      }
      feedbackRating
      user {
        block
        email
        firstName
        lastName
        contactNumber
        profileImage
        createdAt
      }
      warningsCount
    }
  }
`;

const update = gql`
  mutation (
    $id: String!
    $address: String
    $block: Boolean
    $school: String
    $college: String
    $collegeCourseId: String
    $dateOfBirth: String
    $email: String
    $password: String
    $ethnicityId: String
    $firstName: String
    $freeSchoolMeals: Boolean
    $disability: Boolean
    $genderId: String
    $interestsId: [String!]
    $lastName: String
    $contactNumber: String
    $lat: String
    $lng: String
    $profileImage: String
    $sexualOrientationId: String
    $siblings: [SiblingsInput!]
    $university: String
    $universityCourseId: String
    $feedbackRating: String
    $allowPushNotifications: Boolean
    $profileQuestions: [ProfileQuestionInput!]
    $allowDailyBriefPushNotifications: Boolean
  ) {
    editConsultant(
      input: {
        consultantId: $id
        address: $address
        block: $block
        school: $school
        college: $college
        collegeCourseId: $collegeCourseId
        dateOfBirth: $dateOfBirth
        email: $email
        password: $password
        ethnicityId: $ethnicityId
        firstName: $firstName
        freeSchoolMeals: $freeSchoolMeals
        disability: $disability
        genderId: $genderId
        interestsId: $interestsId
        lastName: $lastName
        contactNumber: $contactNumber
        lat: $lat
        lng: $lng
        profileImage: $profileImage
        sexualOrientationId: $sexualOrientationId
        siblings: $siblings
        university: $university
        universityCourseId: $universityCourseId
        feedbackRating: $feedbackRating
        allowPushNotifications: $allowPushNotifications
        allowDailyBriefPushNotifications: $allowDailyBriefPushNotifications
        profileQuestions: $profileQuestions
      }
    ) {
      id
    }
  }
`;

const statistics = gql`
  query ($consultantId: String!) {
    statistics(input: { consultantId: $consultantId }) {
      insightStreak
      topConsultant
    }
    vouchStats(input: { consultantId: $consultantId }) {
      people
      earned
      enrolled
    }
  }
`;

const listConsultantsByBriefId = gql`
  query ($briefId: String!) {
    briefConsultants(input: { briefId: $briefId }) {
      id
      user {
        email
        firstName
        lastName
        contactNumber
      }
    }
  }
`;

const insightsCount = gql`
  query ($consultantId: String!) {
    getInsightsCount(input: { consultantId: $consultantId }) {
      insightStreak
    }
  }
`;

const addWarningToConsultant = gql`
  mutation ($consultantId: String!) {
    addWarning(input: { consultantId: $consultantId }) {
      id
    }
  }
`;

const leaderboard = gql`
  query leaderboard {
    leaderboard {
      firstName
      lastName
      consultantId
      rank
      points
      email
    }
  }
`;

const unansweredProfileQuestions = gql`
  query unansweredProfileQuestions($consultantId: String!) {
    unansweredProfileQuestions(input: { consultantId: $consultantId }) {
      id
      title
      index
      multiple
      type
      min
      max
      minCharacters
      maxCharacters
    }
  }
`;

const addConsultantProfileQuestion = gql`
  mutation addConsultantProfileQuestion(
    $answer: String!
    $consultantId: String!
    $profileQuestionId: String!
  ) {
    addConsultantProfileQuestion(
      input: { answer: $answer, consultantId: $consultantId, profileQuestionId: $profileQuestionId }
    ) {
      id
    }
  }
`;

const profileQuestions = gql`
  query profileQuestions($consultantId: String!) {
    profileQuestions(input: { consultantId: $consultantId }) {
      id
      title
      index
      multiple
      type
      min
      max
      minCharacters
      maxCharacters
      answer
    }
  }
`;

export default {
  logIn,
  profileQuestions,
  register,
  complete,
  leaderboard,
  list,
  get,
  update,
  statistics,
  listConsultantsByBriefId,
  insightsCount,
  addWarningToConsultant,
  unansweredProfileQuestions,
  addConsultantProfileQuestion,
};
