import { trackLogoutUser } from "../service/analytics";

const logOut = () => {
  trackLogoutUser();
  window.localStorage.clear();
  window.sessionStorage.clear();
  window.location.reload();
};

export default logOut;
