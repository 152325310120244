import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useMutation } from "@apollo/client";
import LoginForm from "./LoginForm/LoginForm";
import LoginWrapper from "./LoginWrapper/LoginWrapper";
import UserService from "../../service/user";
import useLogin from "../../hooks/useLogin";

const ResetPassword = () => {
  const { userType: type, token } = useParams();
  const { formatMessage } = useIntl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { logIn, loading: logInLoading } = useLogin(type);

  const onChange =
    (set) =>
    ({ target: { value } }) =>
      set(value);

  const [resetPassword, { loading: resetLoading }] = useMutation(UserService.resetPassword);

  const onSubmit = async (event) => {
    event.preventDefault();
    setError(false);
    try {
      const {
        data: {
          resetPassword: { success },
        },
      } = await resetPassword({
        variables: {
          email,
          password,
          token,
          type,
        },
      });
      if (!success) {
        setError(true);
        return;
      }
      logIn(email, password);
    } catch (e) {
      setError(true);
    }
  };

  return (
    <LoginWrapper>
      <LoginForm
        bodyMessage={error ? "auth.reset.errorSubtitle" : "auth.reset.subtitle"}
        titleMessage={error ? "auth.reset.errorTitle" : "auth.reset.title"}
        submitMessage="auth.reset.submit"
        isLoading={logInLoading || resetLoading}
        onClick={onSubmit}
        className={error && "text-danger"}
        isDisabled={password === ""}
      >
        <Form.Control
          className="w-100 my-2"
          placeholder={formatMessage({ id: "auth.login.email" })}
          value={email}
          onChange={onChange(setEmail)}
        />
        <Form.Control
          className="w-100 my-2"
          type="password"
          placeholder={formatMessage({ id: "auth.login.password" })}
          value={password}
          onChange={onChange(setPassword)}
        />
      </LoginForm>
    </LoginWrapper>
  );
};

export default ResetPassword;
