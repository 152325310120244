import { Redirect, Route, Switch } from "react-router-dom";
import { AuthPaths as Paths } from "../../constants/paths";
import ResetPassword from "./ResetPassword";
import LoginContainer from "./LoginContainer";
import { UserType } from "../../constants/userType";

const ImagenAuth = () => (
  <Switch>
    <Route path={Paths.resetPassword(":userType", ":token")}>
      <ResetPassword />
    </Route>
    <Route path={Paths.loginUserType(":userType")}>
      <LoginContainer />
    </Route>
    <Route path={Paths.login()}>
      <Redirect to={Paths.loginUserType(UserType.CONSULTANT)} />
    </Route>
  </Switch>
);

export default ImagenAuth;
