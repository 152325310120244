import { FormattedMessage } from "react-intl";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styles from "./LoginForm.module.scss";
import Button from "../../common/Button/Button";
import { UserType } from "../../../constants/userType";

const LoginForm = ({
  titleMessage,
  bodyMessage,
  submitMessage,
  className,
  children,
  isDisabled,
  onClick,
  isLoading,
  below,
}) => {
  const { userType } = useParams();
  const isConsultant = userType === UserType.CONSULTANT;

  return (
    <Fragment>
      <div className="w-100">
        <div className={`mt-3 mb-4 text-center ${isConsultant ? "text-white" : "imagen-gray-500"}`}>
          <div className={`${styles.title} font-size-30 font-weight-500 ${className}`}>
            <FormattedMessage id={titleMessage} />
          </div>
          <div className={`font-size-14 ${className}`}>
            <FormattedMessage id={bodyMessage} />
          </div>
        </div>
        {children}
      </div>
      {submitMessage && (
        <div className="w-100 mt-4 mb-3">
          <Button
            id="btn-login"
            className="my-2 font-size-18 w-100 d-flex align-items-center justify-content-center"
            disabled={isDisabled || isLoading}
            onClick={onClick}
            isLoading={isLoading}
            type="submit"
          >
            <FormattedMessage id={submitMessage} />
          </Button>
        </div>
      )}
      {below}
    </Fragment>
  );
};

LoginForm.propTypes = {
  titleMessage: PropTypes.string.isRequired,
  bodyMessage: PropTypes.string.isRequired,
  submitMessage: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  below: PropTypes.node,
};

LoginForm.defaultProps = {
  className: "",
  children: null,
  isDisabled: false,
  isLoading: false,
  submitMessage: "",
  onClick: () => {},
  below: null,
};

export default LoginForm;
