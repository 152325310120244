import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./NavButton.module.scss";

const NavButton = ({ Icon, isCurrent, path, alerts, children }) => (
  <Link
    className={`${styles.button} ${
      isCurrent && styles.current
    } link-reset m-2 p-2 position-relative`}
    to={path}
  >
    {alerts > 0 && (
      <div
        className={`${styles.alerts} position-absolute bg-danger text-white d-flex align-items-center justify-content-center font-size-10`}
      >
        {alerts}
      </div>
    )}
    {children || <Icon />}
  </Link>
);

NavButton.propTypes = {
  children: PropTypes.node,
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  path: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
  alerts: PropTypes.number,
};

NavButton.defaultProps = {
  children: null,
  isCurrent: false,
  alerts: 0,
};

export default NavButton;
